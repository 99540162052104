import React, {useRef, useState, useEffect} from "react";
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons"
import axios from 'axios';
import OtpInput from 'react-otp-input';
import {useCookies} from "react-cookie";
import './css/fonts.css';
import './css/style.css';
import Checkbox from "./components/CheckBox";
import {LoadingCircle} from "./components/SpinCircle";
import QRCode from 'qrcode.react';
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GuardianAPI from './components/GuardianAPI'
import './common/i18n';
import i18next from "i18next";
import {Link, makeStyles, Menu, MenuItem} from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {DownloadIcon, ErrorIcon, OtpIcon, QrIcon, TotpIcon} from "./components/Icons";
import Main from "./components/main";
import storage, {StorageKey as SK} from "./common/storage";
import store, {useStores} from "./common/store";
import {useObserver} from "mobx-react";
import {useSnackbar} from 'notistack';
import {LoginContext} from "./common/context/LoginContext";

// 실제 운영
// const CLIENT_KEY = "d227e269a29d4b44b30e28a9c296ba2f";
// const MAIL_HOST = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? "http://192.168.0.55:8659" : "https://group.fnsvalue.co.kr";

const CLIENT_KEY = "61995b567fe64932b84d6312daa2960f";      // seegene

// const CLIENT_KEY = "1daec78593a643e6b53ce9803ded5916";
// const MAIL_HOST = null;

const FullView = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  min-width: 440px;
  display: flex;
  align-items: center;
  background: center / cover no-repeat url("/img/seegene/seegene_bg.png");
`

const TransparentLogo = styled(Box)`
  width: 45%;
  height: 90%;
  align-self: flex-start;  
  position: absolute;
  z-index: 10;
  background-image: url("/img/seegene/seegene_transparent_logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left top;
  @media( max-width: 1440px){
    display: none
  }
`
const LoginContainer = styled(Box)`
  display: flex;
  justify-self: center;
  margin: auto
`

const LoginImageBox = styled(Box)`
  position: relative;
  display: flex;
  width: 920px;
  height: 760px;
  border-radius: 15px 0px 0px 15px;
  background: center / cover no-repeat url("/img/seegene/seegene_bg_2.png");
  @media (max-width: 1440px) {
    display: none;
  }
`

const SeegeneLogo = styled(Box)`
  width: 220px;
  height: 40px;
  top: 60px;
  left: 60px;
  position: absolute;
  background: transparent center / cover no-repeat url("/img/seegene/seegene_med_logo.png");
`

const LoginBox = styled(Box)`
  display: flex;
  background-color: white;
  flex-direction: column;
  width: 360px;
  height: 640px;
  padding: 60px;
  border-radius: 0px 15px 15px 0px;
   @media (max-width: 1440px) {
    border-radius: 5px;
  };
  @media( max-width: 768px){
    padding: 40px;
    border-radius: 5px;
    width: initial;
    height: initial;
    min-height:480px;
  }
`

const LoginLabel = styled.label`
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 2.188rem;
    color: #2C2C2C;
    line-height: 2.875rem;
    letter-spacing: -0.04rem;
`

const LoginText = styled.label`
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 2.188rem;
    color: #2C2C2C;
    max-width: 210px;
    line-height: 2.875rem;
    letter-spacing: -0.04rem;
    @media( max-width: 768px){
      line-height: 1.875rem;  
    }
`

const LoginItemContainer = styled(Box)`
    position: relative;
    display: flex;
    height: 100%;
    max-width: 360px;
    flex-direction: column;
    margin-top: 45px;
    @media( max-width: 768px){
      margin-top: 40px;
    }
`

const EmailControl = styled.div`
  display: flex;
  height: 22px;
  align-items: center;
  padding: 15px 24px;
  border-radius: 26px;
  background: ${props => ((props.value !== '' || props.error) ? '#FFF' : '#F1F4F9 ')};
  border: ${props => ((props.value !== '' || props.error) ? '1px solid #D8DEE7' : '1px solid #FFF')};
`

const Exclamation = styled(FontAwesomeIcon).attrs({color: '#CC0000', icon: faExclamationCircle})`
  margin: auto auto;
`

const EmailInput = styled.input.attrs({type: 'email', autocomplete: true})`
  font-family: 'Poppins';
  font-size: 0.875rem;
  margin-left: 5px;
  border-width: 0;
  flex: 1;

  background: transparent;
  height: 22px;
  width: 360px;
  line-height: 22px;
  letter-spacing: ${props => (props.error ? '-0.04em' : 'normal')};

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }
  ::-webkit-input-placeholder {
    color: ${props => (props.error ? '#CC0000' : '#8E97AB')};
  }
`

const LoginButton = styled.button`
  width: 100%;
  color: white;
  background-color: #CC0000;
  border-width: 0px;
  border-radius: 26px;
  font-family: 'Poppins';
  font-size: 1rem; 
  font-weight: 700;
  line-height: 56px;
  height: 56px;
  cursor: pointer;
  margin: 10px 0 22px 0;
  @media( max-width: 768px){
    margin: 25px 0 15px 0;
    height: 52px;
    font-size: 1.200rem;
  }
  &:hover {
    // outline: none;
    background-color: #980000;
  }

  &:focus {
    outline: none;
  }

  &:active {
    // outline: none;
    // background-color: #980000;
  }
`;

const CancelBox = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  
  background: #F1F4F9;
  box-sizing: border-box;
  border-radius: 28px;
  border: none;

  font-family: Poppins;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.500rem;
  color: #555555;
  
  height: 56px;
  cursor: pointer;
  
  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }
  @media( max-width: 768px){
    height: 52px;
    font-size: 0.933rem;
  }
`;

const RememberView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const RememberText = styled.span`
  font-family: 'Poppins';
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 21px;
  color: ${props => (props?.checked ? '#2C2C2C' : '#A0A0A0')};
  cursor: pointer;
  margin-left: 6px;
  @media( max-width: 768px){
    font-size: 1rem;
  }
`

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex : ${props => (props?.type === 'QR' ? '1' : 'initial')};
  align-items: center;
  justify-content: ${props => (props?.type === 'QR' ? 'center' : 'space-between')};
  margin-top: ${props => (props?.type === 'LOGIN' ? '70px' : '0')};
  margin-bottom : ${props => (props?.type === 'QR' ? '10px' : '0')};
`

const LoadingContainer = styled(Box)`
  position: absolute;
  width: 100%;
  height: ${props => (props?.type === 'LOGIN' ? '400px' : 'auto')};  
  background: linear-gradient(180deg, #FFFFFF 34.49%, rgba(255, 255, 255, 0.9) 122.79%);
  flex:1;
  flex-direction: column;
  justify-content: space-between;
  display: ${props => (props?.show ? 'flex' : 'none')};
  button {
    margin-bottom : ${props => (props?.type === 'LOGIN' ? '30px' : '0')};
  };
  @media( max-width: 768px){
    height: 100%;
  }
`

const RemainText = styled.label`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.500rem;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.04em;

  color: ${props => (props?.type === 'LOGIN' ? '#217AE3' : '#2C2C2C')};
  span {
    color: #CC0000
  }
  //margin-top:25px;
`
const ErrorText = styled.label`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.500rem;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.04em;

  color: #D40000;

  //margin-top:25px;  
`

const QrTitle = styled.label`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 37px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.04em;

  color: #2C2C2C;
`
const QrSub  = styled.label`

  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.04em;
  /* identical to box height */

  text-align: center;
  margin:10px 0 50px 0;
  color: #888888;
`

const ErrorContainer = styled.div`
  position:absolute;
  width: 100%;
  height:368px;
  top: ${props => (props?.type === 'QR' && '70px')};
  
  // background: #ffffffe6;
  background: linear-gradient(180deg, #FFFFFF 34.49%, rgba(255, 255, 255, 0.9) 122.79%);
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => (props?.type === 'QR' ? 'center' : 'space-between')};
  
  // border : ${props => (props?.type === 'QR' ? '1px solid #dedede' : 'none')};
  flex : ${props => (props?.type === 'QR' ? '1' : 'initial')};
  margin-bottom : ${props => (props?.type === 'QR' ? '10px' : '0')};
`

/* ====================================================================================================== AS-IS ====================================================================================================== */

const useStyles = makeStyles((theme) => ({
    smallLogo: {
        width: 56,
        height: 56,
        transform: 'translate(-5px, 0px)',
        marginBottom: 15
    },
    menuBtn: {
        width: '100%',
        position: 'absolute',
        justifyContent: 'flex-end',
        top: 50,
        right: 40,
        '& > button': {
            width: 260,
            height: 42,
            border: '1px solid #D8DEE7',
            borderRadius: 10,
            background: '#fff',
            fontFamily: 'Poppins',
            fontSize: '0.75rem',
            fontWeight: 500,
            color: '#2C2C2C',
            letterSpacing: '-0.03em',
            display: 'flex',
            alignItems: 'center',
            padding: '14px 20px',
            '&:hover': {
                cursor: 'pointer'
            },
            '&:focus-visible': {
                outline: 'none'
            },
            '& > span': {
                flex: 1,
                textAlign: 'left'
            },
            '& > svg': {
                justifySelf: 'flex-end',
                fill: '#8E97AB'
            }
        }
    },
    menuList: {
        '& .MuiPopover-paper': {
            borderRadius: 10,
            border: '1px solid #D8DEE7',
            // boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.2)',
            padding: 10,
            width: 240,
            marginTop: 5
        },
        '& .MuiListItem-root': {
            fontFamily: 'Poppins',
            fontSize: '0.75rem',
            fontWeight: 500,
            letterSpacing: '-0.01em',
            color: '#2C2C2C',
            height: 38,
            borderRadius: 10,
            '&:hover': {
                backgroundColor: '#FBEFEF',
                color: '#CC0000',
                cursor: 'pointer',
                textDecoration: 'none'
            }
        }
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    idIcon: {
        fill: '#8E97AB'
    },
    tgrisLink: {
        fontFamily: 'Poppins',
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '21px',
        color: "#8E97AB",
        textDecoration: 'underline',
        textUnderlineOffset: '2px',
        '&>span:lang(en)': {
            letterSpacing: '0.02em'
        },
        '&>span:lang(ko)': {
            letterSpacing: '-0.03em'
        },
        '&:hover': {
            color: "#2C2C2C"
        },
        '@media( max-width: 768px)': {
            fontSize: '1rem'
        },
    },
    authOptionsBox: {
        display: "flex",
        columnGap: 12,
        alignSelf: 'flex-end',
        marginTop: 'auto',
        marginBottom: 18,
        '& > button': {
            width: 112,
            height: 112,
            border: '1px solid #D8DEE7',
            borderRadius: 10,
            background: '#FFF',
            fontFamily: 'Poppins',
            color: '#2C2C2C',
            fontSize: '0.813rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: 22,
            '& > svg': {
                marginBottom: 15
            },
            '&:not([disabled]):hover': {
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                border: '1px solid #2C2C2C',
                cursor: 'pointer'
            },
            '&:disabled': {
                color: '#8E97AB',
                '& > svg path': {
                    fill: '#BBC1CC'
                }
            }
        }
    },
    downloadBtn: {
        border: '1px solid #D8DEE7',
        borderRadius: 10,
        background: '#FFF',
        fontFamily: 'Poppins',
        color: '#2C2C2C',
        fontSize: '0.813rem',
        lineHeight: '0.813rem',
        fontWeight: 500,
        height: 43,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > svg': {
            marginRight: 10
        },
        '&:not([disabled]):hover': {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
            border: '1px solid #2C2C2C',
            cursor: 'pointer'
        }
    },
    qrBox: {
        width: 325,
        height: 242,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 30,
        paddingTop: 80,
        alignItems: 'center',
        backgroundImage: `url('/img/seegene/phone_bg.png')`,
        backgroundRepeat: 'no-repeat'
    },
    otpBtnContainer: {
        display: "flex",
        '& > div': {
            marginRight: 10,
            '&:last-child': {
                marginRight: 0
            }
        }
    },
    otpLoginBtn: {
        margin: '20px 0 12px 0 !important'
    },
    otpInput: {
        margin: theme.spacing(1) / 4,
        '& > input' :{
            width: '100% !important',
            height: theme.spacing(8),
            backgroundColor: '#FFF',
            border: '1px solid #D2D2D2',
            borderRadius: 4,
            color: '#217AE3',
            fontFamily: 'Poppins',
            fontSize: '1.75rem',
            fontWeight: '500',
            '@media (max-width: 768px)': {
                width: '1em !important'
            }
        },
        '&:first-child': {
            marginLeft: 0
        },
        '&:last-child': {
            marginRight: 0
        },
        '& > input:focus-visible': {
            outline: 'none',
            border: '2px solid #247DFF'
        }
    },
    otpError: {
        color: '#D40000 !important'
    }
}));


function formatTime(sec) {
    if (sec === 60 || sec === null) {
        return '01:00'
    } else if (sec < 60 && sec > 9) {
        return '00:' + sec
    } else if (sec < 10) {
        return '00:0' + sec
    }
}

const ForwardLinkMenu = () => {

    const classes = useStyles();
    const [openMenu, setOpenMenu] = useState(false);
    const menuOpenBtn = useRef(null);

    const urlList = [
        "https://www.seegene.de/",
        "https://seegenetech.com/",
        "https://seegenecanada.com/",
        "https://www.seegeneme.com/",
        "https://www.seegene.com.mx/",
        "https://www.arrowdiagnostics.it/it/home/",
        "https://seegenebrazil.com.br/"
    ]

    const handlePageOpen = (idx) => {
        window.open(urlList[idx])
    }

    return (
        <Box display='flex' className={classes.menuBtn}>
            <button onClick={() => setOpenMenu(true)} ref={menuOpenBtn}>
                <span>사이트 바로가기</span>
                <ArrowDropDownIcon/>
            </button>
            <Menu anchorEl={menuOpenBtn.current}
                  keepMounted
                  open={openMenu}
                  onClose={() => setOpenMenu(false)}
                  elevation={0}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                  }}
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                  }}
                  className={classes.menuList}
            >
                <MenuItem onClick={() => handlePageOpen(0)}>Seegene Germany GmbH</MenuItem>
                <MenuItem onClick={() => handlePageOpen(1)}>Seegene TECHNOLOGIES Inc. (U.S.A)</MenuItem>
                <MenuItem onClick={() => handlePageOpen(2)}>Seegene CANADA Inc.</MenuItem>
                <MenuItem onClick={() => handlePageOpen(3)}>Seegene MIDDLE EAST</MenuItem>
                <MenuItem onClick={() => handlePageOpen(4)}>Seegene Mexico</MenuItem>
                <MenuItem onClick={() => handlePageOpen(5)}>Arrow Diagnostics S.r.l (Italy)</MenuItem>
                <MenuItem onClick={() => handlePageOpen(6)}>Seegene Brazil Diagnosticos LTDA</MenuItem>
            </Menu>
        </Box>
    )
}


function App(url, config) {
    const {ds} = useStores();
    const [login, setLogin] = useState( false );
    const [loading, setLoading] = useState( false );
    const {enqueueSnackbar} = useSnackbar();

    const [cookies, setCookie, removeCookie] = useCookies(['rememberEmail']);
    const [remainCookies, setRemainCookie, removeRemainCookie] = useCookies(['authContinue']);
    const [email, setEmail] = useState(null);
    const [open, setOpen] = useState({show: false, type:''});
    const [validation, setValidation] = useState({show: false, message: ""});
    const [error, setError] = useState({show: false, message: ""});
    const [visible, isVisible] = useState(false);
    const [countDown, setCountDown] = useState(null);
    const fncClearInterval = useRef(null);
    const [qrData, setQrData] = useState({id:null, url:null});
    const [qrUserKey, setQrUserKey] = useState(null);
    const [state, setState] = useState({ checked: false })
    const [otpCode, setOtpCode] = useState(null);
    const [otpErr, setOtpErr] = useState(false);
    const [otpOpen, setOtpOpen] = useState(false);
    const [totpOpen, setTotpOpen] = useState(false);
    const otpUserKey = useRef(null);

    const stompConnector = useRef(null);
    const isFinished = useRef(false);

    const qr_userKey = useRef(null);
    const qr_channelKey = useRef(null);

    const theme = useTheme();
    const isNotMobile = useMediaQuery(theme.breakpoints.up('md'));

    const classes = useStyles();
    const otpDigit = 6;

    const handleCheckboxChange = ({target}) => {
        setState({ checked: target.checked });
    }

    const customInterval = (time, onClose, timeoutMsg = null) => {
        let count = time;

        const id = setInterval(() => {
            count = count - 1;
            if (count) {
                setCountDown(count);
            } else {
                clear(true);
            }
        }, 1000);


        const clear = (isTimeout) => {
            if (id !== null) {

                if( stompConnector.current !== null ) {

                    stompConnector.current?.deactivate();
                }

                clearInterval(id);

                if (isTimeout && timeoutMsg) {
                    //setOpen({show:false});
                    isFinished.current = true;
                    isVisible(false);
                    setError({show:true, message: timeoutMsg});
                    removeRemainCookie('authContinue');
                }

                if (onClose) {
                    onClose();
                }
                setCountDown(null);
            }
        }

        return () => {
            clear(false)
        };
    }

    useEffect( () => {

        ds.alertFunc = (variant, message) => {
            enqueueSnackbar(message, {variant});
        }

        if (GuardianAPI.hasToken()) {
            GuardianAPI.getMe()
                .then(data => {
                    ds.setLogin(true);
                })
                .catch(err => {
                    ds.setLogin(false);
                })
                .finally( () => {
                    setLogin(ds.isLogin);
                    setLoading(true);
                })
        } else {
            setLoading(true);
        }


    }, []);



    useEffect(() => {
        i18next.changeLanguage('ko');
        if (cookies.rememberMe !== undefined) {
            setEmail(cookies.rememberMe)
            setState({ checked: true });
        }
        if( remainCookies.authContinue !== undefined ) {
            setOpen({show:false, type: 'LOGIN'});
            const startTime = remainCookies.authContinue.startTime;
            const currentTime = Date.now();

            remainCookies.authContinue.authTimeRemaining = Math.floor( (remainCookies.authContinue.authTimeRemaining - (currentTime - startTime)) / 1000) * 1000;

            if( remainCookies.authContinue.authTimeRemaining > 0 ) {
                doConnectWS(remainCookies.authContinue.authQuery, remainCookies.authContinue.socketQuery, remainCookies.authContinue.authTimeRemaining, 'LOGIN');
            } else {
                removeRemainCookie('authContinue');
            }
        }
    }, []);

    const doAccessProcess = (token) => {
        removeRemainCookie('authContinue');
        GuardianAPI.setToken(token);
        GuardianAPI.getMe()
            .then(data => {
                const accessDt = new Date(+new Date() + 3240 * 10000).toISOString().replace("T", " ").replace(/\..*/, '');
                data.accessDt = accessDt;
                storage.setSession(SK.LOGIN_USER_INFO, {data});
                ds.setLogin(true);
                setLogin(true);

            })
            .catch(err => {
                setOpen({show: false, type: ""});
                console.log(err);
            });
    }


    const doLogin = ({isOtpAuth = false}) => {
        resetInfo();

        let P_USER_KEY = otpUserKey.current ? otpUserKey.current : email;

        if (!P_USER_KEY || P_USER_KEY.length === 0) {
            setEmail("");
            setValidation({
                show: true,
                message: i18next.t("Validation.Email")
            });
            return;
        }

        if (state.checked && !otpUserKey.current) {
            setCookie('rememberMe', email, {maxAge: 3600 * 24 * 3})
        }

        isFinished.current = false;

        GuardianAPI.requestAuth(CLIENT_KEY, P_USER_KEY, isOtpAuth)
            .then(res => {
                const rtCode = res.rtCode;
                if (rtCode === 0) {
                    const {userKey, channelKey, authTimeRemaining} = res.data;
                    const socketQuery = GuardianAPI.encodeParams({clientKey: CLIENT_KEY, userKey, channelKey});
                    const authQuery =  GuardianAPI.encodeParams({clientKey: CLIENT_KEY, userKey: P_USER_KEY, channelKey});

                    if (remainCookies.authContinue === undefined) {
                        const params = {socketQuery:socketQuery, authQuery: authQuery, authTimeRemaining: authTimeRemaining, startTime: Date.now()};
                        setCookie('authContinue', params, {maxAge: authTimeRemaining / 1000})
                    }

                    doConnectWS(authQuery, socketQuery, authTimeRemaining, "LOGIN", isOtpAuth);
                }
            })
            .catch(err => {
                console.log(err);
                if( fncClearInterval.current != null ) { fncClearInterval.current(); }
                setEmail("")
                setValidation({show: true, message: i18next.t("Error." + err.rtCode)});
            })

    };

    const doConnectWS = (authQuery, socketQuery, authTimeRemaining, type, isOtpAuth = false) => {
        let userKey = otpUserKey.current ? otpUserKey.current : email;

        fncClearInterval.current = customInterval((authTimeRemaining / 1000) + 1, null,i18next.t("AuthStatus.LoginTimeout"));
        setOpen({show:false, type: type});

        isVisible(true);

        stompConnector.current = GuardianAPI.getStompWS(socketQuery);

        stompConnector.current.onConnect = function( data ) {
            const onClose = () => {
                subscribeStomp.unsubscribe();
                fncClearInterval.current();
            }

            const subscribeCallBack = ( message ) => {
                const data = JSON.parse(message.body);
                const authStatus = data.status;

                if( authStatus === "AuthFailed" || authStatus === "AuthCanceled" || authStatus === "AuthCompleted" ) {

                    isFinished.current = true;
                    onClose();

                    if (authStatus === "AuthCompleted") {

                        if (isOtpAuth) {
                            setOpen({ show: true});
                            setOtpOpen(true);
                        } else {
                            GuardianAPI.getAuthResult(authQuery)
                                .then(res => {
                                    if( res.rtCode === 0 ) {
                                        // doMailHostLogin(res.data, userKey);
                                        doAccessProcess(res.data);
                                    }
                                })
                                .catch(err => {
                                    console.log("getAuthResult error", err);
                                })
                        }
                    } else {
                        switch (authStatus) {
                            case "AuthFailed":
                            case "AuthCanceled":
                                setError({show: true, message: i18next.t("AuthStatus."+authStatus)});
                                isVisible(false);
                                break;
                        }
                    }
                }
            }

            const subscribeStomp = stompConnector.current.subscribe('/user/queue', function(message) {
                if (message.command === "MESSAGE") {
                    subscribeCallBack(message);
                } else {
                    setError({show: true, message: i18next.t("AuthStatus.AuthFailed")});
                }
            });
        };

        stompConnector.current.onWebSocketClose = () => {
            if( !isFinished.current ) {

                GuardianAPI.getAuthResult(authQuery)
                    .then(res => {

                        if( res.rtCode === 0 ) {

                            doAccessProcess(res.data);

                        } else {
                            if( res?.rtCode !== 2010 ) {
                                // parentRef.current?.setErrMsg(api.getErrMsg(data.rtCode));
                                // parentRef.current?.setProgressMsg(null);
                                setError({show: true, message: i18next.t("AuthStatus.AuthFailed")});
                            }
                        }

                    })
                    .catch(err => {
                        console.log("getAuthResult error", err);
                    })

            }

        }

        stompConnector.current.onDisconnect = () => {
        }


    }

    const doCancel = () => {
        isFinished.current = true;

        if (error.show) {
            setOpen({show:false, type: ''});
            return;
        }

        if( fncClearInterval.current() != null ) { fncClearInterval.current(); }

        if( remainCookies.authContinue !== undefined ) { removeRemainCookie('authContinue'); }

        resetInfo();
        setOpen({show:false, type: ''});

        let userKey = otpUserKey.current ? otpUserKey.current : email;

        GuardianAPI.deleteAuth(CLIENT_KEY, userKey, null)
            .then(res => {
                const rtCode = res.rtCode;
                if( rtCode === 0 ) {
                    setError({show: true, message: i18next.t("AuthStatus.AuthCanceled")});
                }
            })
            .catch(err => {
                setError({show: true, message: err.rtMsg});
            })
    }

    const onEnter = (event) => {
        if( event.key === 'Enter') {
            doLogin({isOtpAuth : false});
        }
    }

    const onQrLogin = (event) => {
        resetInfo();
        GuardianAPI.getLoginQrUrl(CLIENT_KEY)
            .then(res => {
                if (res.rtCode === 0) {
                    isFinished.current = false;

                    const QR_DATA = res.data;
                    setQrData({id: QR_DATA.qrId, url: QR_DATA.qrUrl});

                    setOpen({show: true, type: 'QR'});

                    const authTimeRemaining = 60000;
                    // const authTimeRemaining = 1000;

                    stompConnector.current = GuardianAPI.getStompQrWS(QR_DATA.qrId);

                    // 타이머 시작
                    fncClearInterval.current = customInterval((authTimeRemaining / 1000) + 1, null, i18next.t("AuthStatus.LoginTimeout"));

                    stompConnector.current.onConnect = (data) => {

                        const onClose = () => {
                            subscribeStomp.unsubscribe();
                            fncClearInterval.current();
                        }

                        const subscribeCallBack = (message) => {
                            const data = JSON.parse(message.body);
                            const authStatus = data.status;
                            const type = data.type;

                            if (type !== undefined && type === "data") {
                                qr_userKey.current = data.userKey;
                                qr_channelKey.current = data.channelKey;
                            }


                            if (authStatus == "RequestAuth") { setQrUserKey(data.userKey); }


                            if( authStatus === "AuthFailed" || authStatus === "AuthCanceled" || authStatus === "AuthCompleted" ) {

                                isFinished.current = true;

                                if (authStatus === "AuthCompleted") {
                                    onClose();
                                    GuardianAPI.getAuthResult(GuardianAPI.encodeParams({
                                        clientKey : CLIENT_KEY,
                                        userKey   : qr_userKey.current,
                                        channelKey: qr_channelKey.current
                                    }))
                                        .then(res => {
                                            setOpen({show: false});

                                            doAccessProcess(res.data);


                                        }).catch(err => {
                                        console.log("getAuthResult error", err);
                                    });

                                } else {
                                    switch (authStatus) {
                                        case "AuthFailed":
                                        case "AuthCanceled":
                                            setError({show: true, message: i18next.t("AuthStatus." + authStatus)});
                                            isVisible(false);
                                            onClose();
                                            break;
                                    }
                                }
                            }
                        }

                        const subscribeStomp = stompConnector.current.subscribe('/user/queue', function(message){
                            if( message.command === "MESSAGE" ) {
                                subscribeCallBack(message);
                            } else {
                                setError({show: true, message: i18next.t("AuthStatus.AuthFailed")});
                            }
                        });

                    }

                    stompConnector.current.onWebSocketClose = () => {

                        if( !isFinished.current ) {
                            GuardianAPI.getAuthResult(GuardianAPI.encodeParams({
                                clientKey : CLIENT_KEY,
                                userKey   : qr_userKey.current,
                                channelKey: qr_channelKey.current
                            }))
                                .then(res => {
                                    if (res.rtCode === 0) {
                                        setOpen({show: false});
                                        doAccessProcess(res.data);
                                    } else {

                                    }
                                }).catch(err => {
                                console.log("getAuthResult error", err);
                            });
                        }
                    }

                    stompConnector.current.onDisconnect = () => {
                    }

                }
            }).catch(err => {
                console.log("getAuthResult error", err);
            })

    }

    const doQrCancel = (event) => {
        setOpen({show:false, type: ''});

        if( fncClearInterval.current !== null ) { fncClearInterval.current(); }

        if (error.show) {
            return;
        }

        GuardianAPI.deleteAuth(CLIENT_KEY, qrUserKey, qrData.id)
            .then(res => {
                const rtCode = res.rtCode;
                if( rtCode === 0 ) {
                    setError({show: true, message: i18next.t("AuthStatus.AuthCanceled")});
                }
            }).catch(err => {
                console.log(err);
                setValidation({show: true, message: i18next.t("Error." + err.rtCode)});
        });
        setQrUserKey(null);
    }

    const doOTPLogin = (event) => {
        if (!otpCode || otpCode.length !== otpDigit) {
            setValidation({
                show: true,
                message: i18next.t("Validation.Otp")
            });
            setOtpErr(true);
            return;
        }

        resetInfo();

        if (otpOpen) {
            if (email) {
                GuardianAPI.postOtpUserVerify(CLIENT_KEY, email, otpCode)
                    .then(async res => {
                        const rtCode = res.rtCode;
                        if (rtCode === 0) {
                            setOpen({show:false, type: 'LOGIN'});
                            if (state.checked) {
                                setCookie('rememberMe', email, {maxAge: 3600 * 24 * 3})
                            } else {
                                removeCookie('rememberMe')
                            }
                            await timeout(1000);
                            doAccessProcess(res.data);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        setValidation({show: true, message: i18next.t("Error." + err.rtCode)});
                        setOtpErr(true);
                    })
            } else {
                GuardianAPI.getOtpVerify(CLIENT_KEY, otpCode)
                    .then(res => {
                        const rtCode = res.rtCode;
                        if (rtCode === 0) {
                            otpUserKey.current = res.data;
                            doLogin({isOtpAuth : false});
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        setValidation({show: true, message: i18next.t("Error." + err.rtCode)});
                        setOtpErr(true);
                    })
            }
        } else if (totpOpen) {

            GuardianAPI.postTotpUserVerify(CLIENT_KEY,email, otpCode)
                .then(async res => {
                    const rtCode = res.rtCode;
                    if (rtCode === 0) {
                        setOpen({show: false, type: 'LOGIN'});
                        await timeout(1000);
                        doAccessProcess(res.data);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setValidation({show: true, message: i18next.t("Error." + err.rtCode)});
                    setOtpErr(true);
                })
        }
    }

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    const onEmailChange = (event) => {
        setEmail(event.target.value);
        setValidation({show: false, message: ""})
    }

    const onOtpCodeChange = (value) => {
        setOtpErr(false);
        setOtpCode(value);
    }

    const onOtpOpen = () => {
        if (email) {
            doLogin({isOtpAuth : true});
        } else {
            resetInfo();
            setOpen({show:true});
            setOtpOpen(true);
        }
    }

    const onTotpOpen = () => {
        resetInfo();
        if (!email || email.length === 0) {
            setValidation({
                show: true,
                message: i18next.t("Validation.Email")
            });
            return;
        }
        setOpen({show:true});
        setTotpOpen(true);
    }

    const onOtpClose = () => {
        resetInfo();
        setOpen({show:false});
        setOtpOpen(false);
        setTotpOpen(false);
        setOtpCode(null);
    }

    const resetInfo = () => {
        setValidation({show: false});
        setError({show:false});
        isVisible(false);
        setOtpErr(false);
    }

    const contextValue = {
        setLogin,
        onOtpClose
    }

    return useObserver(() => {
        return (
            <FullView>
                <LoginContext.Provider value={contextValue}>
                    <TransparentLogo/>
                    <LoginContainer>
                        <LoginImageBox>
                            <SeegeneLogo/>
                        </LoginImageBox>

                        <LoginBox>
                            { (loading && login)&& <Main/> }
                            { (loading && (!login)) && <>
                            {!open.show &&
                                <React.Fragment>
                                    <img alt="seegene_logo_small" src="/img/seegene/seegene_logo_small.png"
                                         className={classes.smallLogo}/>
                                    <LoginLabel>SEE AHEAD.</LoginLabel>
                                    <LoginText>CHANGE TOMORROW</LoginText>
                                </React.Fragment>
                            }

                            <LoginItemContainer>

                                {!open.show && <React.Fragment>
                                    <EmailControl error={validation.show} value={email || ''}>
                                        {validation.show ? <Exclamation style={{width: 20}}/> :
                                            <PersonIcon className={classes.idIcon}/>}
                                        <EmailInput
                                            value={email || ''}
                                            placeholder={validation.show ? validation.message : '아이디 입력'}
                                            onChange={onEmailChange}
                                            onKeyDown={onEnter}
                                            disabled={visible}
                                            error={validation.show}
                                        />
                                    </EmailControl>

                                    <LoginButton onClick={doLogin} type={"submit"}>로그인</LoginButton>

                                    <RememberView>
                                        <label>
                                            <Checkbox
                                                checked={state.checked}
                                                onChange={handleCheckboxChange}
                                            />
                                            <RememberText checked={state.checked}>아이디 저장</RememberText>
                                        </label>
                                        {/*<Link className={classes.tgrisLink} color='inherit' href='https://www.tigrison.com' target='_blank'>*/}
                                        <Link className={classes.tgrisLink} color='inherit' href='' target='_blank'>
                                            <span lang='en'>TIGRIS</span> <span lang='ko'>아이디&비번 로그인</span>
                                        </Link>
                                    </RememberView>

                                    <Box className={classes.authOptionsBox}>
                                        <button onClick={onQrLogin}>
                                            <QrIcon/>
                                            <span>QR 인증</span>
                                        </button>
                                        <button onClick={onOtpOpen}>
                                            <OtpIcon/>
                                            <span>OTP 인증</span>
                                        </button>
                                        <button onClick={onTotpOpen}>
                                            <TotpIcon/>
                                            <span>TOTP 인증</span>
                                        </button>
                                    </Box>
                                </React.Fragment>}


                                <LoadingContainer show={open.type !== ''} type={open.type}>

                                    {open.type === 'LOGIN' && (
                                        <React.Fragment>
                                            <ColumnContainer type={open.type}>

                                                {error.show
                                                    ? (<React.Fragment>
                                                        <ErrorIcon style={{marginBottom: 35}}/>
                                                        <ErrorText>{error.message}</ErrorText>
                                                    </React.Fragment>)
                                                    : (<React.Fragment>
                                                        <LoadingCircle style={{marginBottom: 30}}/>
                                                        {countDown !== null &&
                                                            <RemainText type={open.type}>{countDown}초 남았습니다</RemainText>}
                                                    </React.Fragment>)
                                                }
                                            </ColumnContainer>
                                            <CancelBox onClick={doCancel}>취소</CancelBox>
                                        </React.Fragment>
                                    )}

                                    {open.type === 'QR' && (
                                        <React.Fragment>
                                            <ColumnContainer type={open.type}>
                                                <QrTitle>QR 코드 로그인</QrTitle>
                                                <QrSub>모바일로 QR코드를 스캔해주세요.</QrSub>
                                                <Box className={classes.qrBox}>
                                                    <RemainText>남은 시간 <span>{formatTime(countDown)}</span></RemainText>
                                                    <QRCode value={qrData?.url}
                                                            size={111}
                                                            imageSettings={{
                                                                src   : '/img/qr_logo.svg',
                                                                width : 33,
                                                                height: 38,
                                                            }}
                                                    />
                                                </Box>
                                                {error.show && (
                                                    <ErrorContainer type={open.type}>
                                                        <ErrorIcon style={{marginBottom: 35}}/>
                                                        <ErrorText>{error.message}</ErrorText>
                                                    </ErrorContainer>
                                                )}
                                            </ColumnContainer>
                                            <CancelBox onClick={doQrCancel}>취소</CancelBox>
                                        </React.Fragment>
                                    )}

                                    {open.type === 'OTP_LOGIN' && (
                                        <React.Fragment>
                                            <ColumnContainer>
                                                <LoadingCircle/>
                                            </ColumnContainer>
                                        </React.Fragment>
                                    )}

                                    {(otpOpen || totpOpen) && open.show &&
                                        <React.Fragment>
                                            <Box display='flex' flexDirection='column'
                                                 onKeyDown={(e) => {
                                                     e.key === 'Enter' && doOTPLogin(e)
                                                 }}>
                                                <QrTitle>{otpOpen ? "OTP" : "TOTP"} 인증 로그인</QrTitle>
                                                <QrSub>모바일로 인증번호를 확인 후 입력해주세요.</QrSub>
                                                <Box className={classes.qrBox}>
                                                    <OtpInput
                                                        className={classes.otpInput}
                                                        value={otpCode}
                                                        onChange={onOtpCodeChange}
                                                        numInputs={otpDigit}
                                                        isInputNum={true}
                                                        hasErrored={otpErr}
                                                        shouldAutoFocus={true}
                                                        errorStyle={classes.otpError}
                                                    />
                                                </Box>
                                            </Box>
                                            <LoginButton onClick={doOTPLogin}
                                                         className={classes.otpLoginBtn}>로그인</LoginButton>
                                            <CancelBox onClick={onOtpClose}>취소</CancelBox>
                                        </React.Fragment>
                                    }

                                </LoadingContainer>
                            </LoginItemContainer>
                            </> }

                        </LoginBox>
                    </LoginContainer>
                </LoginContext.Provider>

            </FullView>
        )
    });
}

export default App;
