import {useTranslation, withTranslation} from 'react-i18next';

export class ErrCode {
    static RT_SUCCESS = 0;
}

export class UserType {
    static FnsManager = 'CMMMCL001';
    static VendorManager = 'CMMMCL002';
    static ClientManager = 'CMMMCL003';
    static User = 'CMMMCL004';

    static isSuperManager = (userType) => {
        return UserType.FnsManager === userType;
    };

    static isVendorManager = (userType) => {
        return (UserType.VendorManager === userType);
    }

    static isClientManager = (userType) => {
        return (UserType.ClientManager === userType);
    }

    static isManager = (userType) => {
        return (UserType.FnsManager === userType) || (UserType.VendorManager === userType) || (UserType.ClientManager === userType);
    };

    static isUser = (userType) => {
        return (UserType.User === userType);
    };
}

export class User {
    seq = null;
    userKey = null;
    clientKey = null;
    clientName = null;
    userType = null;
    userStatus = null;
    phoneNum = null;
    name = null;
    email = null;
    empNo = null;
    authType = null;
    regDt = null;
    clientList = null;
    accessibleClient = null;
    deviceList = null;
    accessDt = null;

    constructor(userData) {
        Object.keys(userData)
            .filter(key => key in this)
            .forEach(key => {
                this[key] = userData[key];
            });
    }

    isSuperManager = () => {
        return UserType.isSuperManager(this.userType);
    };

    isVendorManager = () => {
        return UserType.isVendorManager(this.userType);
    }

    isClientManager = () => {
        return UserType.isClientManager(this.userType);
    }

    isManager = () => {
        return UserType.isManager(this.userType);
    };

    isUser = () => {
        return UserType.isUser(this.userType);
    };

    getAccessClientUserType = (clientKey) => {
        let accessUserType = null;

        if (this.accessibleClient !== undefined && this.accessibleClient !== null) {
            if( this.accessibleClient[clientKey] !== undefined ) {
                if (this.accessibleClient[clientKey].clientKey === clientKey) {
                    accessUserType = this.accessibleClient[clientKey].userType;
                }
            }
        }

        return accessUserType;
    }

}

export class AuthStatus {
    static RequestAuth = 'RequestAuth';
    static CreateChannel = 'CreateChannel';
    static SelectNodes = 'SelectNodes';
    static StartAuth = 'StartAuth';
    static StartVerificationOfNodes = 'StartVerificationOfNodes';
    static CompleteVerificationOfNodes = 'CompleteVerificationOfNodes';
    static RequestCancel = 'RequestCancel';
    static AuthCanceled = 'AuthCanceled';
    static AuthFailed = 'AuthFailed';
    static AuthCompleted = 'AuthCompleted';

    static isFinish = (status) => {
        return (status === AuthStatus.AuthFailed) || (status === AuthStatus.AuthCanceled) || (status === AuthStatus.AuthCompleted);
    };

    static isProgress = (status) => {
        return !AuthStatus.isFinish(status);
    };

    static isSuccess = (status) => {
        return (status === AuthStatus.AuthCompleted);
    };
}

export class OtpType {
    static DeviceOTP = 'DeviceOTP';
    static DeviceTOTP = 'DeviceTOTP';
    static MailOTP = 'MailOTP';
    static SmsOTP = 'SmsOTP';

    static isOnLine = (type) => {
        return (type === OtpType.DeviceOTP);
    }

    static isOffLine = (type) => {
        return !OtpType.isOnLine(type);
    }

    static isDeviceOTP = (type) => {
        return type === OtpType.DeviceOTP || type === OtpType.DeviceTOTP;
    }

    static isNotDeviceOTP = (type) => {
        return !OtpType.isDeviceOTP(type);
    }
}

export class CountryTelNum {
    static SouthKorea = '82';
    static Indonesia = '62';
    static Malaysia = '60';
    static UniteStates = '1';

    static getArray = () => {
        return [
            CountryTelNum.SouthKorea,
            CountryTelNum.Indonesia,
            CountryTelNum.Malaysia,
            CountryTelNum.UniteStates
        ];
    }
}

export class CommonCodeValue {
    static translateKey = 'CommonCode';

    static getText = (key) => {

        if (key === undefined || key === null || key === "") {
            return null;
        }

        const {t} = useTranslation();

        return t(this.translateKey + '.' + key);
    };
}

export class UserSex extends CommonCodeValue {
    static Male = 'CMMSEX001';
    static Female = 'CMMSEX002';
};

export class CountryCode extends CommonCodeValue {
    static SouthKorea = 'CMMLGN001';    //	대한민국
    static UniteStates = 'CMMLGN002';   //	미국
    static Japan = 'CMMLGN003'; //	일본
    static Malaysia = 'CMMLGN004';  //	말레이시아
    static Vietnam = 'CMMLGN005';   //	베트남
    static China = 'CMMLGN006'; //	중국
};


export class QuestionType extends CommonCodeValue {
    static All = 'ALL';
    static License = 'CMMQAT001';
    static Partner = 'CMMQAT002';
    static Auth = 'CMMQAT003';
    static TechnicalSupport = 'CMMQAT004';
}

export class QuestionStatus extends CommonCodeValue {
    static General = 'CMMQSS001';
    static Deleted = 'CMMQSS009';
}

export class QuestionAnswerStatus extends CommonCodeValue {
    static Wait = 'CMMQAS001';
    static Complete = 'CMMQAS002';
}

export class UserStatus extends CommonCodeValue {
    static Normal = 'CMMMST001';
    static Wait = 'CMMMST002';
    static Block = 'CMMMST003';
    static Deleted = 'CMMMST004';
    static Withdraw = 'CMMMST005';
        static Reject = 'CMMMST006';
}

export class AuthResult extends CommonCodeValue {
    static AuthSuccess = 'CMMASC001';
    static AuthFail = 'CMMASC002';
    static AuthCancel = 'CMMASC003';
    static AuthTimeOut = 'CMMASC004';
}

export class AuthPlatform extends CommonCodeValue {
    static Others = 'CMMAPF000';
    static GuardianPortal = 'CMMAPF001';
    static GuardianApp = 'CMMAPF002';
    static Windows = 'CMMAPF003';
}

export class ClientStatus extends CommonCodeValue {
    static EnableClient = 'CMMCLS001';
    static DisableClient = 'CMMCLS002';
}

export class LanguageCode extends CommonCodeValue {
    static English = 'CMMLNG001'
    static Korean = 'CMMLNG002'
}

export class UseStatusCode extends CommonCodeValue {
    static Enable = 'CMMUSE001'
    static Disable = 'CMMUSE002'
}

export class SubClientBehavior extends CommonCodeValue {
    static SubClientsFollow = 'CMMSCB001'
    static SubClientsCustomizable = 'CMMSCB002'
}

export class Category extends CommonCodeValue {
    static Member = 'CMMMHT001'
    static Device = 'CMMMHT002'
}

export class EventType extends CommonCodeValue {
    static StatusChange = 'CMMMHE001'
    static RegistrationNewMember = 'CMMMHE010'
    static UpdateMemberInfo = 'CMMMHE011'
    static ResetAuthCount = 'CMMMHE012'
    static UpdateAuthIcon = 'CMMMHE013'
    static UpdateAuthType = 'CMMMHE014'
    static UpdateMemberType = 'CMMMHE015'
    static RegistrationUserClient = 'CMMMHE016'
    static RegistrationDevice = 'CMMMHE020'
    static UpdateDevice = 'CMMMHE021'
}

export class AgreementType extends CommonCodeValue {
    static termsConditions = 'CMMAGR001'
    static personalInformation = 'CMMAGR002'
    static IdentificationInformation = 'CMMAGR003'
}

export class ApprovalStatus extends CommonCodeValue {
    static Allow = "CMMAPR001";
    static Disallow = "CMMAPR002";
}

export class ClientContractType extends CommonCodeValue {
    /** On-Cloud */
    static OnCloud = "CMMCTT001";
    /** On-Premise */
    static OnPremise = "CMMCTT002";
}

export class LicenseQuotaType extends CommonCodeValue {
    /** Unlimited License */
    static Unlimited = "CMMLIC000";
    /** User License */
    static ByUser = "CMMLIC001";
    /** Auth License */
    static ByAuth = "CMMLIC002";
}

export class LicenseStatus extends CommonCodeValue {
    /** 활성화 */
    static Active = "CMMLCS010";
    /** 비활성화 */
    static InActive = "CMMLCS011";
    /** 만료 */
    static Expire = "CMMLCS012";
    /** 유효하지 않음 */
    static Invalid = "CMMLCS013";
    /** 폐기 */
    static Revoke = "CMMLCS014";

}

export class UserVerifyType extends CommonCodeValue {
    static Email = "CMMDUP001";
    static PhoneNum = "CMMDUP002";
}

export class LoginType extends CommonCodeValue {
    /** 모두 사용(extraKey 우선 적용) **/
    static Both = "CMMLGT003";

    /** UserKey로 로그인 **/
    static UserKey = "CMMLGT001";

    /** ExtraKey로 로그인 **/
    static ExtraKey = "CMMLGT002";
}

export class TimeUnit extends CommonCodeValue {
    static Year = 'CMMTMU001';
    static Month = 'CMMTMU002';
    static Day = 'CMMTMU003';
}
