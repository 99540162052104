
function SRArrow() {

    return (
        <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L4.5 3.85714L1 7" stroke="white" strokeWidth="1.5"/>
        </svg>

    )
}

export {SRArrow}
