import React from 'react'
import styled from 'styled-components'

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Icon = styled.svg`
  fill: none;
  padding: 3px;
  stroke: ${props => (props?.checked ? '#CC0000' : '#BBC1CC')};
  stroke-width: 3px;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 22px;
  height: 22px;
  background: #EBEEF3;
  border-radius: 5px;
  transition: all 150ms;
  cursor: pointer;
`

const Checkbox = ({ className, checked, ...props }) => (
    <CheckboxContainer className={className}>
        <HiddenCheckbox checked={checked} {...props} />
        <StyledCheckbox checked={checked}>
            <Icon viewBox="0 0 15 12" checked={checked}>
                <path d="M2 6.5L6 10.5L13 2" strokeLinecap="round" strokeLinejoin="round"/>
            </Icon>
        </StyledCheckbox>
    </CheckboxContainer>
)

export default Checkbox
