import {Box, Button, makeStyles} from "@material-ui/core";
import {SRArrow} from "./Arrows";
import {useStores} from "../common/store";
import axios from "axios";
import i18next from "i18next";
import GuardianAPI from "./GuardianAPI";
import storage, {StorageKey as SK} from "../common/storage";
import {useContext} from "react";
import {LoginContext} from "../common/context/LoginContext";
import {useObserver} from "mobx-react";

const useStyles = makeStyles((theme) => ({
    container: {
        display      : 'flex',
        flexDirection: "column",
    },
    infoBox  : {
        display       : 'flex',
        flex          : 1,
        justifyContent: 'space-between'
    },
    user     : {
        display      : "flex",
        flexDirection: "column",
        '& *'        : {
            fontFamily: 'Poppins',
            fontStyle : 'normal',
            fontSize  : '12px',
            lineHeight: '18px',
        },
        '& p'        : {
            fontWeight: 500,
            fontSize  : 20,
            lineHeight: '30px',
            margin    : 0,
            color     : '#3D3D3D',
        },
        '& > span'   : {
            fontWeight   : 400,
            letterSpacing: '0.05rem',
            color        : '#99A1AF',
            margin       : '10px 0',
        },
        '& button'   : {
            background  : '#FBEFEF',
            borderRadius: "5px",
            width       : 66,
            padding     : ' 2px 8px 1px',
            '& span'    : {
                fontWeight: 500,
                color     : '#CC0000',
            }
        }
    },
    service  : {
        margin       : '50px 0 0 0',
        padding      : 0,
        listStyle    : 'none',
        '& li'       : {
            display       : 'flex',
            flex          : 1,
            alignItems    : 'center',
            justifyContent: 'space-between',
            height        : 60,
            border        : '1px solid #E6E9EE',
            boxShadow     : '0px 1px 1px #B8C1CF',
            boxSizing     : 'border-box',
            borderRadius  : '10px',
            padding       : '0 30px',
            marginBottom  : 15,
            '& p'         : {
                margin    : 0,
                fontFamily: 'Poppins',
                fontStyle : 'normal',
                fontWeight: 500,
                fontSize  : '14px',
                lineHeight: '21px',
                color     : '#B8C1CF',

            },
            '& > span'    : {
                display       : 'flex',
                width         : 21,
                height        : 21,
                background    : '#DFE5ED',
                alignItems    : 'center',
                justifyContent: 'center',
                borderRadius  : '10px'
            }
        },
        '& li.active': {
            cursor    : 'pointer',
            '& p'     : {
                color: '#2C2C2C',
            },
            '& > span': {
                background: '#B8C1CF',
            }
        },
        '& li.active:hover':{
            border    : '1px solid #2C2C2C',
            boxShadow : '0px 2px 4px #B8C1CF',
            '& > span': {
                background: '#CC0000',
            }
        }
    },
}));

export default function Main() {
    const {ds} = useStores();
    const classes = useStyles();
    const { setLogin, onOtpClose } = useContext(LoginContext);
    const accessDt = storage.getSession(SK.LOGIN_USER_INFO).data?.accessDt;



    const doMailHostLogin = () => {
        if (!GuardianAPI.MAIL_HOST) {
            alert("dev success");
            return;
        }

        axios.post(
            `${GuardianAPI.MAIL_HOST}/login`,
            {token:GuardianAPI.token}
        ).then(res => {
            const {redirectURL} = res.data;
            if (redirectURL) {
                window.open(res.data.redirectURL, '_blank');
            } else {
                alert(i18next.t("Error.undefined"));
            }
        }).catch(() => {
            alert(i18next.t("Error.undefined"))
        });
    }

    const onAccessTigrison = () => {
        doMailHostLogin()
    }
    const onLogout = () => {
        onOtpClose();
        setLogin(false);
        storage.removeSession(SK.ACCESS_TOKEN);
        ds.showInfoAlert("로그아웃 되었습니다.");
    }

    return useObserver(() => {
        return (
        <Box>
            <Box className={classes.infoBox}>
                <Box className={classes.user}>
                    <p>안녕하세요</p>
                    <p>{ds.user.name} 님</p>
                    <span>{accessDt}</span>

                    <Button onClick={onLogout}>로그아웃</Button>
                </Box>
                <Box>
                    <img src={"img/seegene/user_state.png"} alt="User State"/>
                </Box>
            </Box>


            <ul className={classes.service}>
                <li className={"active"} onClick={onAccessTigrison}>
                    <p>티그리스</p>
                    <span> <SRArrow/>  </span>
                </li>
                <li>
                    <p>AS400 세션 접속 프로그램</p>
                    <span> <SRArrow/>  </span>
                </li>

                <li>
                    <p>mCLIS</p>
                    <span> <SRArrow/>  </span>
                </li>
                <li>
                    <p>xCeed-i</p>
                    <span> <SRArrow/>  </span>
                </li>
                <li>
                    <p>인트라넷</p>
                    <span> <SRArrow/>  </span>
                </li>
                <li>
                    <p>모니터링 사이트</p>
                    <span> <SRArrow/>  </span>
                </li>
            </ul>
        </Box>
    )});
}
